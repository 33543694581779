import React from 'react';
import { Link} from 'gatsby';
import Img from 'gatsby-image';
import * as classes from './VerticalsCard.module.css';

function VerticalsCard({title, description, type, imgName, link}) {
  return (
    <div
      className={classes.card}
    >
      <Link className={classes.link} to={link}>
      <img className={classes.img}
          key={`${type}`}
          src={require(`../../../../assets/images/connect/web/verticals/${imgName}`).default}
            alt={title}
            />
 <div className={classes.rect}>
            <div className={classes.text}>{type}</div>
          </div>
        <div className={classes.body}>
         
          <div className={classes.title}>{title}</div>
          <p className={classes.description}>{description}</p>
        </div>
      </Link>
    </div>
  );
}

export default VerticalsCard;
